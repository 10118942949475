import { Turbo } from '@hotwired/turbo-rails'
import TurboPower from 'turbo_power'
import LocalTime from 'local-time'
import 'chartkick/chart.js'

import '~/components'
import '~/controllers'

import 'balloon-css'
import '~/styles/application.css'

window.Turbo = Turbo
TurboPower.initialize(Turbo.StreamActions)
LocalTime.start()